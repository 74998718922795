<!-- Benefits.vue -->
<template>
    <div class="bg-white rounded-lg p-6 shadow-md">
      <h2 class="text-2xl text-gray-900 font-bold mb-4">Benefits</h2>
      <ul class="list-disc list-inside text-gray-700">
        <li v-for="benefit in benefits" :key="benefit">{{ benefit }}</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        benefits: [
          "Simplify your knowledge sharing by creating personalized chatbots in minutes.",
          "Monetize your expertise by adding paywalls to your chatbots and generating revenue.",
          "Reduce the burden of answering repetitive questions and free up time for more important tasks.",
          "Expand your reach and impact by making your chatbots public and accessible to a wider audience.",
          "Gain insights into user interactions and preferences through chatbot analytics.",
        ],
      };
    },
  };
  </script>
  