<template>
    <div class="w-full fixed top-0 left-0 z-50 px-20">
        <div class="rounded-b-lg h-20 bg-green-500 text-white flex items-center justify-center">
            <div class="flex-shrink mr-2 text-lg" v-if="currentAlert.icon" v-html="currentAlert.icon">

            </div>
            <div class="flex-shrink">
                {{currentAlert.message}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created(){
        setTimeout(() => {
          this.$store.commit("updateState", {
            state: "currentAlert",
            body: null,
          });
        }, 3000);
    },
    computed:{
        currentAlert(){
            return this.$store.state.currentAlert;
        }
    }
}
</script>