var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-56 pt-20 bg-gray-900 flex flex-col h-full border-r border-gray-200 p-6"},[_c('div',{staticClass:"w-full h-full"},[_c('router-link',{staticClass:"w-full flex space-x-2 items-center h-12 rounded px-4 bg-transparent",class:_vm.$route.name == 'ChatBots'
          ? 'bg-gray-100 text-gray-800 hover:text-blue-600'
          : 'text-white ',attrs:{"to":{ name: 'ChatBots' }}},[_c('div',{staticClass:"flex-shrink"},[_c('i',{staticClass:"fa-solid fa-robot"})]),_c('div',{staticClass:"whitespace-nowrap flex-grow text-sm cursor-pointer"},[_vm._v(" My Chatbots ")])]),(_vm.currentUser.subscription)?_c('router-link',{staticClass:"w-full flex space-x-2 items-center h-12 rounded px-4 bg-transparent",class:_vm.$route.name == 'Billing'
          ? 'bg-gray-100 text-gray-800 hover:text-blue-600'
          : 'text-white ',attrs:{"to":{ name: 'Billing' }}},[_c('div',{staticClass:"flex-shrink"},[_c('i',{staticClass:"fa-solid fa-dollar"})]),_c('div',{staticClass:"whitespace-nowrap flex-grow text-sm cursor-pointer"},[_vm._v(" Billing ")])]):_vm._e(),(!_vm.currentUser.subscription)?_c('router-link',{staticClass:"mt-10 w-full flex border border-transparent space-x-2 items-center h-12 rounded px-4 bg-transparent",class:_vm.$route.name == 'Pricing'
          ? 'bg-yellow-500 text-white'
          : 'bg-yellow-100 border-yellow-300 text-yellow-600 ',attrs:{"to":{ name: 'Pricing' }}},[_c('div',{staticClass:"flex-shrink"},[_c('i',{staticClass:"fa-solid fa-credit-card"})]),_c('div',{staticClass:"whitespace-nowrap flex-grow text-sm cursor-pointer"},[_vm._v(" Upgrade to Pro ")])]):_vm._e()],1),_vm._m(0),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10 w-full h-56 flex items-center justify-center rounded bg-white bg-opacity-10 rounded-xl px-4"},[_c('div',{staticClass:"w-full text-center"},[_c('h2',{staticClass:"text-gray-300"},[_vm._v("0/5 Bots Available")]),_c('div',{staticClass:"w-full h-4 rounded-full bg-white my-4"}),_c('div',{staticClass:"w-full bg-purple-600 text-white h-12 flex items-center justify-center rounded-lg"},[_vm._v("Upgrade to Pro")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-12 w-full"},[_c('p',{staticClass:"text-xs text-gray-500"},[_vm._v(" Copyright 2023 "),_c('br'),_vm._v("All Rights Reserved ")])])
}]

export { render, staticRenderFns }