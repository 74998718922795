<template>
 <div class="w-full">
 

    <!-- Navigation Bar -->
    <nav class="hidden sm:flex justify-center items-center bg-white p-4 shadow-md mb-8">
      <div class="mr-auto flex">
        <h2 class="font-bold text-gray-800">
          <img src="@/assets/wiseText.svg" class="w-20" alt="wise1 logo">
        </h2>
        <img src="@/assets/squarelogo.svg" alt="w1 logo" class="w-8">
      </div>
     
      <div class="md:flex space-x-4">
        <router-link :to="{name: 'Login'}" class="bg-gray-100 hover:bg-gray-200 text-gray-600 text-sm h-14 flex items-center justify-center px-4 rounded cursor-pointer">Sign In</router-link>
        <router-link :to="{name: 'SignUp'}" class="bg-purple-900 hover:bg-blue-800 text-white px-4 rounded cursor-pointer h-14 flex items-center justify-center">Are you a creator?</router-link>
      </div>
    </nav>

    <div class="sm:hidden fixed bg-white shadow-lg h-20 items-center w-full flex">
    <!-- Hamburger Button -->
   

    <!-- Header -->
    <div class="flex w-full px-4">
      <!-- Centered Logo -->
      <div class="flex-shrink flex">
        <img src="@/assets/wiseText.svg" class="w-20" alt="wise1 logo">
        <img src="@/assets/squarelogo.svg" alt="w1 logo" class="w-8">
      </div>

      <div class="flex-shrink ml-auto">
        <div class="md:flex flex">

          <router-link :to="{name: 'Login'}" class="h-10 flex items-center justify-center bg-gray-100 whitespace-nowrap hover:bg-gray-200 text-gray-600 text-sm px-3 text-sm rounded-l-full cursor-pointer">Sign In</router-link>
        <router-link :to="{name: 'SignUp'}" class="h-10 flex items-center justify-center bg-purple-900 whitespace-nowrap hover:bg-blue-800 text-white px-3 rounded-r-full text-sm cursor-pointer">Creators</router-link>

      </div>
      </div>

      <!-- ... (rest of the header content) ... -->
    </div>
  </div>

     <!-- Header -->
  <div class="container mx-auto text-center py-20 px-0 flex flex-wrap">
    <div class="w-full sm:w-1/2">

      <div class="hidden sm:block w-full text-center mb-10"><span class="bg-purple-600 text-white rounded-full px-5 py-2 text-sm font-bold">
        Launch with us - Wise1 has just entered beta
      </span></div>
      <h1 class="mt-20 sm:mt-0  text-5xl text-gray-900 font-bold mb-4">Your Ultimate Chatbot Marketplace</h1>
      <p class="text-gray-700 text-xl sm:px-0 px-16">Subscribe to the wisdom of your favorite creators.</p>

      <div class="w-full flex justify-center space-x-2 mt-6">
        <div class="flex-shrink">
          <div class="w-40">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png" alt="Google Play">
          </div>
        </div>
        <div class="flex-shrink">
          <div class="w-40">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/640px-Download_on_the_App_Store_Badge.svg.png" alt="Google Play">
          </div>
        </div>
      </div>

     <div class="w-full hidden sm:flex justify-center">
      <div class="flex-shrink bg-gray-100 rounded-full px-4 text-sm py-2 text-gray-600 text-center mt-20">
        <span class="font-bold">Are you a creator?</span> <span>Create and earn from your own personlised chatbot</span><br /> <router-link class="text-blue-600" :to="{name: 'HomePage'}">Get started for free</router-link>
      </div>
     </div>

    </div>
    <div class="w-full sm:w-1/2 mt-6 sm:mt-0">
      <img src="@/assets/mobile2.png" alt="My Image" class="sm:-mt-20">
    </div>
      
    </div>

    <!-- Solutions Section -->
    <section class="bg-white sm:bg-gray-100">
      <div class="max-w-7xl mx-auto">
    <div class="flex flex-col md:flex-row mx-4 md:mx-20 my-8 items-center">
        <div class="md:w-1/2 text-center md:text-left md:pr-40">
            <h1 class="text-4xl font-semibold mb-4">Embrace the wisdom of creators you already know and love</h1>
            <p class="text-sm text-gray-500">Unlock invaluable knowledge crafted by your favorite creators. No more scouring through pages of recipe books or textbooks, no more getting lost in endless Google searches. Trust in the expertise of the people you already admire and embrace the clarity that comes with curated wisdom. Elevate your learning journey with ease, as wise1 empowers you to explore a world of reliable insights, precisely tailored to your needs.</p>
        </div>
        <div class="md:w-1/2 text-center md:text-right">
            <!-- Replace the src attribute with the image URL -->
            <img src="@/assets/1.png" alt="My Image">
        </div>
    </div>
  </div>
</section>

<section class="bg-white">
      <div class="max-w-7xl mx-auto p-10 flex flex-wrap items-center justify-center">
          <div class="w-full sm:w-1/2 sm:pr-32">

            <h2 class="text-4xl font-semibold sm:tracking-none tracking-tight">Learn <span class="text-blue-600">what</span> you want <br /><span class="text-blue-800">when</span> you want <br />from <span class="text-purple-800">who</span> you want</h2>
            <p class="mt-6 text-sm text-gray-500">Unleash the power of influential brains from across the globe - trainers who shape champions, coaches who ignite passions, wise parents who nurture greatness, food experts who tantalize taste buds. Say goodbye to tedious book searches; with wise1, you hold the key to knowledge at your fingertips. Feed your mind with wisdom, inspiration, and expertise whenever you desire it. Join the league of seekers and dive into a world of boundless possibilities.</p>
            
            <div class="w-full flex flex-wrap mt-10">
            <div class="mr-1 mb-1 flex-shrink rounded-full text-xs sm:text-sm bg-gray-200 py-2 px-4 text-gray-500">
            Coaches & Trainers
          </div>

          <div class="mr-1 mb-1 flex-shrink rounded-full text-xs sm:text-sm bg-gray-200 py-2 px-4 text-gray-500">
            Food & Drink Experts
          </div>
          <div class="mr-1 mb-1 flex-shrink rounded-full text-xs sm:text-sm bg-gray-200 py-2 px-4 text-gray-500">
            Wise Parents
          </div>
          <div class="mr-1 mb-1 flex-shrink rounded-full text-xs sm:text-sm bg-gray-200 py-2 px-4 text-gray-500">
            Gardening
          </div>
          <div class="mr-1 mb-1 flex-shrink rounded-full text-xs sm:text-sm bg-gray-200 py-2 px-4 text-gray-500">
            Sports
          </div>
          <div class="mr-1 mb-1 flex-shrink rounded-full text-xs sm:text-sm bg-gray-200 py-2 px-4 text-gray-500">
            Gaming
          </div>
          <div class="mr-1 mb-1 flex-shrink rounded-full text-xs sm:text-sm bg-gray-200 py-2 px-4 text-gray-500">
            Spirituality
          </div>
          <div class="mr-1 mb-1 flex-shrink rounded-full text-xs sm:text-sm bg-gray-200 py-2 px-4 text-gray-500">
            & more...
          </div>
        </div>
          </div>
          <div class="w-full sm:w-1/2 mt-6 sm:mt-0">
            <img src="@/assets/2.png" alt="solutions">
          </div>
   
        </div>
     
</section>

<section>
  <div class="sm:flex max-w-7xl mx-auto p-10 hidden space-x-2 justify-center">
    <img src="@/assets/banner.jpg" alt="Parenting solution" class="w-full rounded-2xl shadow-lg">
    </div>
</section>


<section class="bg-white">
      <div class="max-w-7xl mx-auto p-10 flex flex-wrap items-center justify-center">
          <div class="w-full sm:w-1/2 sm:pr-32">

            <span class="bg-purple-600 text-white rounded-full px-5 py-2">Are you a creator?</span>
            <h2 class="text-4xl font-semibold mt-4">Turn your brain into a personlised search engine and earn from it</h2>
            <p class="mt-6 text-sm text-gray-500">Your wisdom is valuable, your worked hard for it and people see value in it. But scaling information has never been possible until now. Create an account with wise1 and start selling access to your very own AI Chatbots. Add books, documents, links, youtube clips and free text to your topics. Watch your dashboard as your wisdom reaches the far edges of the globe. Everyone is searching for a way to earn more, why not earn from what you already have - your wisdom.</p>
            <div class="bg-purple-900 mt-6 flex w-full sm:w-56 h-20 items-center justify-center text-white rounded text-lg">
              Create your free account
            </div>
       
          </div>
          <div class="w-full sm:w-1/2">
            <img src="@/assets/3.png" alt="solutions">
          </div>
   
        </div>
     
</section>

 </div>
</template>

<script>
import Benefits from '@/components/landingPage/Benefits.vue';
import Solutions from '@/components/landingPage/Solutions.vue';

export default {
  components: {
    Benefits,
    Solutions,
  },
}
</script>