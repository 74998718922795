<template>
  <div
    class="w-56 pt-20 bg-gray-900 flex flex-col h-full border-r border-gray-200 p-6"
  >
    <div class="w-full h-full">
      <router-link
        :to="{ name: 'ChatBots' }"
        :class="
          $route.name == 'ChatBots'
            ? 'bg-gray-100 text-gray-800 hover:text-blue-600'
            : 'text-white '
        "
        class="w-full flex space-x-2 items-center h-12 rounded px-4 bg-transparent"
      >
        <div class="flex-shrink">
          <i class="fa-solid fa-robot"></i>
        </div>
        <div class="whitespace-nowrap flex-grow text-sm cursor-pointer">
          My Chatbots
        </div>
      </router-link>

     

      <router-link v-if="currentUser.subscription"
        :to="{ name: 'Billing' }"
        :class="
          $route.name == 'Billing'
            ? 'bg-gray-100 text-gray-800 hover:text-blue-600'
            : 'text-white '
        "
        class="w-full flex space-x-2 items-center h-12 rounded px-4 bg-transparent"
      >
        <div class="flex-shrink">
          <i class="fa-solid fa-dollar"></i>
        </div>
        <div class="whitespace-nowrap flex-grow text-sm cursor-pointer">
          Billing
        </div>
      </router-link>

      <router-link v-if="!currentUser.subscription"
        :to="{ name: 'Pricing' }"
        :class="
          $route.name == 'Pricing'
            ? 'bg-yellow-500 text-white'
            : 'bg-yellow-100 border-yellow-300 text-yellow-600 '
        "
        class="mt-10 w-full flex border border-transparent space-x-2 items-center h-12 rounded px-4 bg-transparent"
      >
        <div class="flex-shrink">
          <i class="fa-solid fa-credit-card"></i>
        </div>
        <div class="whitespace-nowrap flex-grow text-sm cursor-pointer">
          Upgrade to Pro
        </div>
      </router-link>
    

      
    </div>
    <div class="mb-10 w-full h-56 flex items-center justify-center rounded bg-white bg-opacity-10 rounded-xl px-4">
        <div class="w-full text-center">
          <h2 class="text-gray-300">0/5 Bots Available</h2>
          <div class="w-full h-4 rounded-full bg-white my-4"></div>
          <div class="w-full bg-purple-600 text-white h-12 flex items-center justify-center rounded-lg">Upgrade to Pro</div>
        </div>
      </div>
    <div class="h-12 w-full">
      <p class="text-xs text-gray-500">
        Copyright 2023 <br />All Rights Reserved
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    currentUser(){
      return this.$store.state.currentUser
    }
  }
}
</script>