<template>
  <div id="app">
    <transition name="slide-down">
      <alert-top-bar v-if="currentAlert" />
    </transition>
    <router-view />
  </div>
</template>

<script>
import alertTopBar from "@/components/alerts/topBar";
export default {
  computed: {
    currentAlert() {
      return this.$store.state.currentAlert;
    },
  },
  components: {
    alertTopBar,
  },
};
</script>