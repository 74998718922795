<template>
  <div class="w-full flex flex-col h-screen">
    <primary-nav />
    <div class="w-full flex h-full text-gray-700">
      <side-nav />

      <div class="flex-grow h-full pt-10">
        <div class="max-w-7xl mx-auto p-10">
          <div
            class="w-full bg-purple-100 border border-purple-300 text-purple-600 text-sm rounded-lg flex items-center justify-center h-12"
          >
            <span
              class="rounded bg-purple-600 text-white rounded px-2 py-1 font-bold mr-2"
              ><i class="fa-solid fa-meteor"></i> TRY PRO</span
            >
            Profit from your chatbots and scale to millions
          </div>
          <div
            class="w-full mb-6 flex items-center"
          >
            <div class="flex-shrink">
              <h1 class="text-3xl font-bold text-gray-800">Chatbots</h1>
            </div>
            <div class="flex-shrink ml-auto">
              <div @click="createChatbot()"
                class="w-40 h-16 hover:bg-blue-700 cursor-pointer rounded-lg bg-blue-600 text-white flex items-center justify-center"
              >
                <i class="fa-solid fa-plus mr-2 text-xs"></i> Create Chatbot
            </div>
            </div>
          </div>

          <div class="w-full" v-if="bots.length > 0">
            <chatbot-tile v-for="(bot, index) in bots" :key="index" :bot="bot" />
          </div>

          <div
            class="w-full h-full mt-56 flex items-center justify-center"
            v-else
          >
            You have not created any chatbots yet
            <span @click="createChatbot"
              class="ml-2 cursor-pointer hover:text-blue-700 text-blue-600 text-white rounded-lg"
              >Create your first chatbot now?</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBots, addItem } from "@/firebaseUtils";
import ChatbotTile from "@/components/cards/chatbotTile";
import PrimaryNav from "@/components/navs/primaryNav.vue"
import SideNav from "@/components/navs/sideNav.vue"
export default {
  data() {
    return {
      bots: [],
    };
  },
  methods: {
    async initiate() {
      this.bots = await getBots(this.currentUser.id);
    },
    async createChatbot(){
        const item = await addItem({collection: 'bots', body: {...this.formData, userId: this.currentUser.id}});
        this.$router.push({name: 'CreateChatBot', params:{id: item.id}})
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    this.initiate();
  },
  components: {
    ChatbotTile,
    SideNav,
    PrimaryNav
  },
};
</script>